import "./App.css";
import Inicio from "./views/Inicio";
import Nosotros from "./views/Nosotros";
import Productos from "./views/Productos";
import Contacto from "./views/Contacto";
import NosotrosSecond from "./views/NosotrosSecond";
import AutomatizacionPorCableado from "./views/AutomatizacionPorCableado";
import CentralDeCobroAutomatizada from "./views/CentralDeCobroAutomatizada";
import Robotina from "./views/Roobotina";

function App() {
  return (
    <div className="App">
      <Inicio />
      <Nosotros />
      <NosotrosSecond />
      <Productos />
      <AutomatizacionPorCableado />
      <CentralDeCobroAutomatizada />
      <Robotina />
      <Contacto />
    </div>
  );
}

export default App;
