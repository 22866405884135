import "../App.css";
import centraldepago from "../img/centraldepago.png";

function Robotina() {
  return (
    <div className="robotinaBackground">
      <h2>Central de Cobro Robotina</h2>
      <div className="divTextAndImgsRobotina">
        <div className="divImgRobotina">
          <img src={centraldepago} alt="" />
        </div>
        <div className="divTextRobotina">
          <h3>Detalles</h3>
          <ul>
            <li>
              Con Acquatronix central de pago podrás tener el 100% de control de
              tus ventas ya que el cliente es el que se encargara de pagar y
              activar sus propios equipos.
            </li>
            <li>
              Cuenta con un modulo de tarjetas para obtener puntos y colocarles
              saldo de dinero en efectivo, con esto podrás tener clientes
              cautivos.
            </li>
            <li>Por el momento solo puede pagarse en efectivo y con puntos.</li>
            <li>
              La central cuenta con una capacidad para almacenar 300 billetes y
              $1,800 pesos en monedas.
            </li>
            <li>Podrás ahorrar un 50% en capital humano</li>
            <li>Imprime un ticket con el detalle de su compra.</li>
            <li>
              Además cuenta con un diseño de seguridad para cualquier tipo de
              intento de robo.
            </li>
            <li>
              Puedes colocar promociones por día , hora y por tipo de equipo.
            </li>
            <li>Tiene reportes de ventas diarias, de uso de equipo, etc.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Robotina;
