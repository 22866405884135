import "../App.css";
import Loundry from "../img/Loundry.png";

function Nosotros() {
  return (
    <div id="Nosotros" className="nosotrosBackground">
      <div className="divLaundrypos_nosotros">
        <img
          className="imgLaundry_nosotros"
          src={Loundry}
          alt="ImagenLaundryPOS"
        />
        <div className="textoNosotros">
          <p>
            Acquatronix Laundry POS es un software para administración y control
            de lavanderias de fácil uso. Es una solución integral de punto de
            venta diseñado específicamente para lavanderías que simplifica su
            operación y manejo resolviendo las necesidades de los propietarios.
            Consiste de un software y un hardware que controlan el accionamiento
            de las lavadoras y secadoras de forma administrada y temporizada.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Nosotros;
