import "../App.css";

function NosotrosSecond() {
  return (
    <div className="nosotrosSecondBackground">
      <h2>¿Por qué elegir Acquatronix Laundry POS?</h2>
      <p>
        Con nuestro Software para punto de venta (POS) obtienes los siguientes
        beneficios
      </p>
      <div className="divTarjetasNosotrosSecond">
        <div className="tarjetasNosotrosSecond">
          <i class="fas fa-chart-line"></i>
          <span>Mejora el control administrativo</span>
        </div>
        <div className="tarjetasNosotrosSecond">
          <i class="fas fa-chart-bar"></i>
          <span>Proporciona reportes detallados de ventas e inventario</span>
        </div>
        <div className="tarjetasNosotrosSecond">
          <i class="fas fa-handshake"></i>
          <span>Mejora el servicio al cliente</span>
        </div>
        <div className="tarjetasNosotrosSecond">
          <i class="fas fa-tags"></i>
          <span>
            Genera tus promociones o utliza las tarjetas de cliente frecuente
          </span>
        </div>
        <div className="tarjetasNosotrosSecond">
          <i class="fas fa-business-time"></i>
          <span>
            Control administrativo apartir del hardware para activación de
            equipos
          </span>
        </div>
        <div className="tarjetasNosotrosSecond">
          <i class="fas fa-tasks"></i>
          <span>Ordena fácilmente tus productos</span>
        </div>
        <div className="tarjetasNosotrosSecond">
          <i class="fas fa-funnel-dollar"></i>
          <span>Reduce costos a través de la automatización</span>
        </div>
        <div className="tarjetasNosotrosSecond">
          <i class="fas fa-hand-holding-usd"></i>
          <span>Conoce tus ganancias diarias, por mes o periodos</span>
        </div>
      </div>
    </div>
  );
}

export default NosotrosSecond;
