import "../App.css";
import Form from "../components/Form";
import Lavakleen from "../img/Lavakleen.jpg";
import mayalav from "../img/mayalav.jpg";
import proclean from "../img/proclean.jpg";
import dryclean from "../img/dryclean.jpg";

function Contacto() {
  return (
    <div id="Contacto" className="contactoBackground">
      {/* <div className="nuestrosClientesBanda">
        <h2>Algunos de nuestros Clientes</h2>
        <div className="divClientesTarjtas">
          <img src={Lavakleen} alt="imagenLoundry" className="imgCLientes" />
          <img src={mayalav} alt="imgMayalav" className="imgCLientes" />
          <img src={proclean} alt="imgProclean" className="imgCLientes" />
          <img src={dryclean} alt="imgDryclean" className="imgCLientes" />
        </div>
      </div> */}
      <Form />
      <footer>
        <div className="divIconos">
          <div className="menuFooter">
            <div>
              <a href="#Inicio">Inicio</a>
              <a href="#Nosotros">Nosotros</a>
              <a href="#Productos">Productos</a>
            </div>
            <div>
              <a href="#Contacto">Contacto</a>
              <a href="https://dev.d26tyjduf5jv28.amplifyapp.com/">
                Iniciar Sesión
              </a>
              <a href="#Administración">Administración</a>
            </div>
          </div>
          <div className="divIconosFooter">
            <a target="blank" href="https://www.facebook.com/acquatronix">
              <div>
                <i class="fab fa-facebook-f"></i>
              </div>
            </a>
            <a
              target="blank"
              href="https://www.youtube.com/channel/UC1Wy-64DN7wxppijjIww7zA/featured"
            >
              <div>
                <i class="fab fa-youtube"></i>
              </div>
            </a>
            <a
              target="blank"
              href="http://wa.me/+525515039019?text=Deseo%20contactar%20a%20Acquatronix%20%20"
            >
              <div>
                <i class="fab fa-whatsapp"></i>
              </div>
            </a>
          </div>
        </div>
        <p>Copyright © Acquatronix 2021</p>
      </footer>
    </div>
  );
}

export default Contacto;
