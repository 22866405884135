import "../App.css";
import "../components/Navbar";
import Navbar from "../components/Navbar";
import acquatronix_logo from "../img/acquatronix_logo.png";
import whatsapplogo from "../img/whatsapplogo.svg";

function Inicio() {
  return (
    <div id="Inicio" className="inicioBackgroun">
      <Navbar />
      <div className="capa_inicio">
        <div className="section_centerInicio">
          <img
            className="acquatronix_logo"
            src={acquatronix_logo}
            alt="Acquatronix"
          />
          <h1>Software y Automatización Para Lavanderias</h1>
        </div>
      </div>
      <a
        className="btnWhatsApp"
        target="blank"
        href="http://wa.me/+525515039019?text=Deseo%20contactar%20a%20Acquatronix%20%20"
      >
        <img className="iconWhatsApp " src={whatsapplogo} alt="logowhatsapp" />
      </a>
    </div>
  );
}

export default Inicio;
