import "../App.css";
import acq500 from "../img/acq500.png";
import acq1000 from "../img/acq1000.png";

function AutomatizacionPorCableado() {
  return (
    <div className="AutomatizacionBackground">
      <div>
        <h3>Automatización</h3>
        <h3>Metodo por Cableado</h3>
        <p>Nuestra automatización esta probada con las siguientes marcas.</p>
        <section className="sectionList">
          <section>
            <span>IPSO</span>
            <span>SPEED QUEEN</span>
            <span>HUEBSCH</span>
            <span>WASCOMAT</span>
            <span>PRIMUS</span>
            <span>UNIMAC</span>
          </section>
          <section>
            <span>LG</span>
            <span>SAMSUNG</span>
            <span>MAYTAG</span>
            <span>GIRBAU</span>
            <span>FRIGIDAIRE</span>
            <span>WHIRLPOOL</span>
          </section>
        </section>
      </div>

      <div className="divImagenesAutomatizaon">
        <img className="imagenAutomatizacion" src={acq500} alt="acq500" />
        <img className="imagenAutomatizacion" src={acq1000} alt="acq1000" />
      </div>
    </div>
  );
}

export default AutomatizacionPorCableado;
