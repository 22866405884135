import React from "react";
import { useForm, ValidationError } from "@formspree/react";

function ContactForm() {
  const [state, handleSubmit] = useForm("mleavdpn");
  if (state.succeeded) {
    return (
      <div>
        <p className="mensajeFormulario">Gracias por contactarnos</p>
      </div>
    );
  }
  return (
    <div className="formularioBackground">
      <div className="sectionTituloContacto">
        <h2>CONTACTO</h2>
      </div>
      <div className="sectionFrom_datosContacto">
        <form className="fomr_comp" onSubmit={handleSubmit}>
          {/* <label htmlFor="nombre">Nombre</label> */}
          <input
            id="nombre"
            type="text"
            name="nombre"
            placeholder="   Nombre"
          />
          <ValidationError
            prefix="Nombre"
            field="email"
            errors={state.errors}
          />

          {/* <label htmlFor="email">Email</label> */}
          <input id="email" type="email" name="email" placeholder="   Email" />
          <ValidationError prefix="Email" field="email" errors={state.errors} />

          {/* <label htmlFor="telefono">Télefono</label> */}
          <input
            id="telefono"
            type="tel"
            name="telefono"
            placeholder="   Télefono"
          />
          <ValidationError
            prefix="Telefono"
            field="telefono"
            errors={state.errors}
          />

          <textarea id="message" name="message" placeholder="  Mensaje" />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
          <button type="submit" disabled={state.submitting}>
            Enviar
          </button>
        </form>
        <div className="datosContacto">
          <p>Correo de contacto: ventas@acquatronix.com</p>
          <p>Télefono de contacto: +52 1 55 1503 9019</p>
          <p>Ciudad de México</p>
        </div>
      </div>
    </div>
  );
}
function Form() {
  return <ContactForm />;
}
export default Form;
