import "../App.css";

function CentralDeCobroAutomatizada() {
  return (
    <div className="centralDeCobroBackground">
      <div>
        <div>
          <h2>Central de Cobro</h2>
          <p className="textoCentrlDeCobro">"Robotina"</p>
          <span className="textoCentrlDeCobro">
            ¿Te gustaría hacer más eficiente tu negocio? Sin control por un
            humano, este producto puede ayudarte en tu lavanderia de
            autoservicio, haciendo el cobro y activación de los equipos por
            medio de la caja de cobro, compatible también con tarjetas de
            puntos.
          </span>
        </div>
        <div className="divTarjetasCobroAutomatico">
          <div className="tarjetasCobroAutomatico">
            <i class="fas fa-money-bill-alt bg-white"></i>
            <span>
              Recibe pagos en efectivo de monedas y billetes o pago con puntos.
            </span>
          </div>
          <div className="tarjetasCobroAutomatico">
            <i class="fas fa-desktop bg-white"></i>
            <span>Fácil uso, intuitivo. Interfaz amigable</span>
          </div>
          <div className="tarjetasCobroAutomatico">
            <i class="fas fa-id-card bg-white"></i>
            <span>
              Acepta y verifica tarjetas de RFID para dar descuentos y/o puntos
            </span>
          </div>
          <div className="tarjetasCobroAutomatico">
            <i class="fas fa-lock bg-white"></i>
            <span>
              La caja de seguridad es totalmente de metal y se puede empotrar al
              piso o a la pared.
            </span>
          </div>
        </div>
        <div>
          <span className="textoCentrlDeCobro">
            Acquatronix Central de Pago ofrece 5 años de garantía en el sistema
            de control electrónico. Capacitación a los usuarios y soporte
            técnico presencial o remoto. Soporte 24x7
          </span>
        </div>
      </div>
    </div>
  );
}

export default CentralDeCobroAutomatizada;
