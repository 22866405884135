function Productos() {
  return (
    <div id="Productos" className="productosBackground">
      <h2>Productos</h2>
      <div className="divTarjetas">
        <div className="tarjetasProductos">
          <i class="fas fa-4x fa-desktop text-primary mb-4"></i>
          <h3>Aplicación de Escritorio</h3>
          <p>
            Punto de venta para venta de autoservicio, servicio por encargo y
            tintoreria.
          </p>
        </div>
        <div className="tarjetasProductos">
          <i class="fas fa-4x fa-code text-primary mb-4"></i>
          <h3>Aplicación web</h3>
          <p>
            Administrador donde podrá monitorear, facturar y muchas cosas más.
          </p>
        </div>
        <div className="tarjetasProductos">
          <i class="fas fa-4x fa-mobile-alt text-primary mb-4"></i>
          <h3>APP</h3>
          <p>Proximamente...</p>
        </div>
      </div>
    </div>
  );
}

export default Productos;
