import "./Navbar.css";
import menu from "../img/lista.svg";
import acquatronix_logo_transparente from "../img/acquatronix_logo_transparente.png";

function Navbar() {
  return (
    <nav className="nav" id="navbar">
      <img
        className="logoAcquatronixNav"
        src={acquatronix_logo_transparente}
        alt="acquatronix_logo_transparente"
      />
      <input type="checkbox" id="btnMenu" />
      <label htmlFor="btnMenu" className="menucelular">
        {" "}
        <img className="iconoMenu" src={menu} alt="menu" />
      </label>

      <ul className="nav-items">
        <li className="nav-item">
          <a href="#Inicio">Inicio</a>
        </li>
        <li className="nav-item">
          <a href="#Nosotros">Nosotros</a>
        </li>
        <li className="nav-item">
          <a href="#Productos">Productos</a>
        </li>
        <li className="nav-item">
          <a href="#Contacto">Contacto</a>
        </li>
        <li className="nav-item">
          <a href="https://dev.d26tyjduf5jv28.amplifyapp.com/">
            Iniciar Sesión
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
